import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import AboutImage from "./Images";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  Content,
  Wrapper,
  BannerImageMD,
  BannerImageSM,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImage from "../BannerImg";
import banner from "../../images/about/035_Gippsland.png";
import bannerSm from "../../images/about/035_Gippsland_sm.png";

const Prosthodontist = () => {
  return (
    <>
      <Breadcrumb pageTitle="About Our Principal Prosthodontist">
        <BreadcrumbItem
          Link="/about"
          Text="About Our Principal Prosthodontist"
        />
      </Breadcrumb>

      <BannerImageMD>
        <BannerImage imagesrc={banner} />
      </BannerImageMD>
      <BannerImageSM>
        <BannerImage imagesrc={bannerSm} />
      </BannerImageSM>
      <PageContainer>
        <Container>
          <Wrapper>
            <ContentsContainer>
              <Title>Our Principal Prosthodontist</Title>
              <Content>
                Dr. Miro De Hoyos is a specialist prosthodontist with more than
                18 years of experience in this field. He has changed people's
                lives through his specialty by helping them to recover their
                confidence, and self-esteem by giving back beautiful and
                functional smiles. He devotes his passion to educating others
                and runs regular tutoring sessions for dentists that share their
                passion for this specialty. He has taught at several
                universities in Australia and overseas. He has served as chair
                of the Waverley metropolitan ADA regional group for the last 12
                years. He was a recipient of several scholarships in his home
                country in Mexico and in Singapore. He is a Fellow of the
                Academy of Australian and New Zealand Prosthodontists. His forte
                is large reconstructive cases and complex management of severe
                grinding, dry mouth, and broken-down dentitions. His doctoral
                thesis was related to Erosive patterns and is quite capable of
                re-establishing the oral environment. Outside his surgery, Dr.
                Miro enjoys spending quality time with his young family: his
                wife, 2 daughters, and his son. In his limited spare time, he
                enjoys a good round of golf, loves snow skiing, and riding his
                cruiser bike.
              </Content>
            </ContentsContainer>
            <ImageContainer>
              <AboutImage />
            </ImageContainer>
          </Wrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default Prosthodontist;
