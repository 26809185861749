import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const Container = styled.div`
  display: block;
  margin-bottom: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 3rem;
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;
export const ImageContainer = styled.div`
  flex-basis: 30%;
  border-radius: 50%;

  @media (min-width: ${breakpoints.md}) {
    padding: 2rem 6rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 5rem 0 0 3rem;
    padding: 0;
  }
`;
export const ContentsContainer = styled.div`
  flex-basis: 70%;
  margin-left: 0;
  margin-bottom: 0;
  @media (min-width: ${breakpoints.md}) {
    margin: 1rem 0 2rem 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin: 1rem 0 10rem 0;
  }
`;
export const Title = styled.h1`
  font-size: 1.3rem;
  padding-bottom: 2rem;
`;
export const Content = styled.p`
  padding-bottom: 1rem;
  line-height: 1.8rem;
  font-family: "Raleway", sans-serif;
`;
export const ContentBottom = styled.div`
  font-weight: 500;
  padding-bottom: 4rem;
`;
export const BannerImageMD = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`;
export const BannerImageSM = styled.div`
  display: block;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;