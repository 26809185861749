import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

const StyledImg = styled(Img)`
  border-radius: 50%;
`;

const AboutImage = () => {
  const data = useStaticQuery(graphql`
    query {
      artWork: file(relativePath: { eq: "about/029_Gippsland.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <StyledImg fluid={data.artWork.childImageSharp.fluid} />;
};

export default AboutImage;
